import React from "react";
import { Card, Button, Badge } from "react-bootstrap";
import { motion } from "framer-motion";
import { FaCalendarAlt } from "react-icons/fa";

function ProjectCard({ imgPath, title, description, demoLink, date, isMajor, isCollegeProject, isMinor }) {
  return (
    <motion.div whileHover={{ scale: 1.05 }}>
      <Card className="project-card-view shadow-lg rounded overflow-hidden border border-gray-300" style={{ backgroundColor: "#68187a", color: "white", minHeight: "500px", display: "flex", flexDirection: "column" }}>
        <Card.Img
          variant="top"
          src={imgPath}
          alt={title}
          style={{
            width: "100%",
            height: "200px",
            objectFit: "contain",
            padding: "10px",
            flexShrink: 0, // Prevent image from shrinking
          }}
        />

        <Card.Body className="p-4 text-center" style={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <div>
            <Card.Title className="text-xl font-bold mb-2" style={{ color: "white" }}>{title}</Card.Title>
            <Card.Text className="text-gray-300 mb-3" style={{ overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 4, WebkitBoxOrient: "vertical" }}>{description}</Card.Text>
          </div>

          <div>
            <div className="d-flex justify-content-center gap-2 mb-3">
              {isMajor && <Badge bg="danger">Major Project</Badge>}
              {isCollegeProject && <Badge bg="primary">College Project</Badge>}
              {isMinor && <Badge bg="success">Minor Project</Badge>}
            </div>

            <div className="d-flex align-items-center justify-content-center gap-2 text-light mb-3">
              <FaCalendarAlt className="text-light" />
              <small className="fw-bold" style={{ color: "white" }}>{date}</small>
            </div>

            {demoLink && (
              <Button
                style={{ backgroundColor: "#68187a", borderColor: "#68187a", color: "white" }}
                href={demoLink}
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 fw-bold shadow-sm rounded-pill"
              >
                View Project
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

export default React.memo(ProjectCard);