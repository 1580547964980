import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/abishekpaudel.png";
function Home2() {
  return (
    <Container fluid className="home-about-section" id="about" style={{ paddingBottom: "0" }}>
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em", fontFamily: "'Poppins', sans-serif" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body" style={{ fontFamily: "'Open Sans', sans-serif" }}>
              Hey there! 👋 I'm a software developer with a passion for building efficient and scalable systems.
              I thrive on solving complex challenges, optimizing performance, and crafting seamless backend architectures.
              <br />
              <br />
              My expertise spans across <i>
                <span className="purple">JavaScript, Node.js, MySQL, MongoDB, Neo4j, and C++.</span>
              </i> I specialize in developing high-performance APIs using <b className="purple">gRPC</b> and <b className="purple">Protocol Buffers</b>, ensuring smooth and reliable communication between microservices.
              <br />
              <br />
              With a strong foundation in database management, I enjoy designing efficient queries and leveraging <b className="purple">Neo4j </b>
              for graph-based applications like recommendation systems. Frameworks like <b className="purple">Express.js</b> help me bring
              my ideas to life with clean and maintainable code.
              <br />
              <br />
              Beyond coding, I'm always exploring emerging technologies in AI and machine learning. Whether it's optimizing algorithms
              or diving into new frameworks, I love staying ahead of the curve. When I take a break, you’ll probably find me
              cheering for my favorite cricket team! 🏏
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <img
              src={myImg}
              className="img-fluid"
              alt="avatar"
              style={{ width: "100%", height: "auto", marginTop: "-70px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social" style={{ marginTop: "-50px" }}>
            {/* Add social media links if needed */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
