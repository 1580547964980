import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

// Import images
import collegeImage from "../../Assets/Projects/college.jpeg";
import Fastbanking from "../../Assets/Projects/GlobalSmart Logo 1.svg";
import humanityImage from "../../Assets/Projects/balance_humanity_main_logo.png";
import globalJuniorImage from "./../../Assets/Projects/junior.svg";

function Projects() {
  // Animation variants for framer-motion
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <h1 className="project-heading">
            My Recent <strong className="purple">Works</strong>
          </h1>
          <p style={{ color: "white" }}>
            Here are a few projects I've worked on recently.
          </p>

          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            {/* Global Smart Plus (Major Project) */}
            <Col md={4} className="project-card">
              <motion.div variants={cardVariants}>
                <ProjectCard
                  imgPath={Fastbanking}
                  isBlog={false}
                  title="Global Smart Plus"
                  description="Experienced backend developer at Global Smart Plus, specializing in microservices architecture and gRPC. Designed and implemented APIs for features like locker applications, e-card loading, Khalti integration, Viber messaging, and personal finance management, enhancing digital banking solutions."
                  demoLink="https://globalonline.gibl.com.np/"
                  date="2021 - Present"
                  isMajor={true}
                />
              </motion.div>
            </Col>

            {/* Global Junior App */}
            <Col md={4} className="project-card">
              <motion.div variants={cardVariants}>
                <ProjectCard
                  imgPath={globalJuniorImage}
                  isBlog={false}
                  title="Global Junior App"
                  description="A fintech platform designed to teach children financial literacy through earning, learning, saving, and spending. Built with Node.js and gRPC, this app empowers young minds with essential money management skills."
                  demoLink="https://www.globalimebank.com/products/digital-payments/global-junior/"
                  date="2023 - 2025"
                  isMajor={true}
                />
              </motion.div>
            </Col>

            {/* Balance Humanity */}
            <Col md={4} className="project-card">
              <motion.div variants={cardVariants}>
                <ProjectCard
                  imgPath={humanityImage}
                  isBlog={false}
                  title="Balance Humanity"
                  description="A fintech platform for targeted aid distribution in disaster-prone regions. I developed secure authentication, KYC verification, and integrated imePay's API for interbank fund transfers."
                  demoLink="https://github.com/avhishek-49/Balance-Humanity.git"
                  date="2022 - 2023"
                  isCollegeProject = {true}

                />
              </motion.div>
            </Col>

            {/* School College Management */}
            <Col md={4} className="project-card">
              <motion.div variants={cardVariants}>
                <ProjectCard
                  imgPath={collegeImage}
                  isBlog={false}
                  title="School College Management"
                  description="A comprehensive college management system with role-based access control. Built with Node.js and React.js, it streamlines academic and administrative tasks for educational institutions."
                  demoLink="https://github.com/avhishek-49/college-management-project"
                  date="2021 - 2022"
                  isCollegeProject = {true}

                />
              </motion.div>
            </Col>
          </Row>
        </motion.div>
      </Container>
    </Container>
  );
}

export default Projects;