import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiMysql,
  DiNodejs,
  DiMongodb,
  DiGit,
} from "react-icons/di";
import {
  SiNeo4J,
  SiRabbitmq,
  SiExpress,
  SiDocker,
} from "react-icons/si";

const techDetails = {
  DiJavascript1: "Utilized JavaScript for backend logic and API development.",
  DiNodejs: "Built robust backend systems using Node.js runtime.",
  DiMongodb: "Managed NoSQL databases effectively with MongoDB.",
  DiMysql: "Handled structured data storage using MySQL.",
  DiGit: "Collaborated and managed code versions with Git.",
  CgCPlusPlus: "Developed foundational projects using C++.",
  SiNeo4J: "Explored graph databases for complex data with Neo4j.",
  SiRabbitmq: "Implemented message queuing systems via RabbitMQ.",
  SiExpress: "Designed RESTful APIs using Express.js framework.",
  SiDocker: "Streamlined deployments using Docker containers."
};

function Techstack() {
  const [hovered, setHovered] = useState(null);

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {Object.entries({
        DiJavascript1,
        DiNodejs,
        DiMongodb,
        DiMysql,
        DiGit,
        CgCPlusPlus,
        SiNeo4J,
        SiRabbitmq,
        SiExpress,
        SiDocker,
      }).map(([key, Icon]) => (
        <Col xs={4} md={2} className="tech-icons" key={key}>
          <div
            onMouseEnter={() => setHovered(key)}
            onMouseLeave={() => setHovered(null)}
            style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <Icon />
            {hovered === key && (
              <div style={{
                position: "absolute",
                background: "rgba(0, 0, 0, 0.75)",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
                fontSize: "10px",
                textAlign: "center",
                maxWidth: "80px",
                overflow: "hidden"
              }}>
                {techDetails[key]}
              </div>
            )}
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default Techstack;